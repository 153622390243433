import Link from "next/link";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Image from 'next/image';

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container py-20 text-center" style={{width: '100%' }}>
          <a href="mailto:contact@otakuinu.com" style={{ textDecoration: 'underline' }}>contact@otakuinu.com</a>
          <div className="flex space-x-5 mt-5" style={{width: '100%', justifyContent: 'center'}}>
                {socialIcons.map((item) => {
                  const { id, href, text } = item;
                  return (
                    <Link href={href} key={id}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="group cursor-pointer"
                      >
                        <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                          <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                        </svg>
                      </a>
                    </Link>
                  );
                })}
              </div>
        </div>
      </footer>
    </>
  );
};

export default footer;
